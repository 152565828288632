import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageProps } from 'gatsby'

// Models | Types
import { SeriesModel } from '../../../models'

// Templates
import PageTemplate from '../PageTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Components
import EndOfSeries from '../../organisms/EndOfSeries'
import DefaultHelmet from '../../atoms/DefaultHelmet'

const EndOfSeriesTemplate: React.FC<PageProps<any, SeriesModel>> = ({
  pageContext: series,
}) => {
  const { language } = useContext(LocalizationContext)
  const { name, description } = series

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'End of Series' : 'Fin de la Serie'}
          {`: ${name[language]}`}
        </title>
        <meta
          name="description"
          content={description ? description[language] : ''}
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? `Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, biografía, ${name[language]}, end of series`
              : `Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, bio, ${name[language]}, fin de la seie`
          }
        />
        <DefaultHelmet />
      </Helmet>
      <EndOfSeries series={series} language={language} />
    </PageTemplate>
  )
}

export default EndOfSeriesTemplate
